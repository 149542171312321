.carousel-item{
  height: 100%;
  background-color: #06B6AA;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.carousel-title{
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  padding-top: 16px;
}
.carousel-image{
  border-radius: 8px;
  width: 216px !important;
  height: 216px;
}

#carousel-image-1 {
  width: 285px !important;
  height: 160px;
  border-radius: 0px;
  border: 2px solid #FFF
}

.carousel-text{
  color: white;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 18px 34px 40px;
}
