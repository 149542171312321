.promo-claim {
  background-color: #E5E5E5;
  overflow: auto;

  .page-content {
    background-color: #E5E5E5;
    background: linear-gradient(to bottom, #06B6AA 0px, #06BAAA 275px, rgba(0, 0, 0, 0) 275px, rgba(0, 0, 0, 0) 100%);
  }
}
.ant-modal-centered .ant-modal{
  vertical-align: bottom;
}
.ant-modal{
  margin: 0;
  padding: 0;
  width:100vw;
  max-width: 100vw;
  animation-duration: 0.2s;
}
.answered .ant-modal-content {
  pointer-events: none;
}
.ant-modal-body{
  padding: 0;
  
  .drip-question-modal {
    display: flex;
    flex-direction: column;
    padding: 24px;
    padding-bottom: 32px;

    .drip-question-image {
      height: 64px;
      align-self: center;
    }
    .drip-question-title {
      padding: 14px 20px 24px 20px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #272727;
    }
    .drip-option-group {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      overflow-x: auto;
    }
    .drip-option {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      &.answered {
        pointer-events: none;
      }
      .option-image {
        width: 45px;
        height: 45px;
      }
      .option-value {
        margin-top: 7.5px;
        font-weight: bold;
        font-size: 11px;
        line-height: 16px;
        text-align: center;
        color: #686868;
        width:70px;
      }
    }
  }
  .thanks-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 63px 0;
    
    .thanks-suki {
      width: 200px;
      height: 140px;
    }
  }
}