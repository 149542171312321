.home-layout {
  .page-content {
    background-color: #F2F2F2;
  }
  .nav-section {
    position: relative;
    height: 20px;
    margin-bottom: 10px;

    .layout {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 0 20px;

      .welcome-field {
        display: inline-block;
        margin: 0;
        padding: 4px 12px;
        background: #07A197;
        border-radius: 4px 4px 0px 0px;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #FFFFFF;
      }

      .button-layout {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
        .seperator {
          width: 1px;
          background-color: #07A197;
        }
      
        .button-view {
          flex: 1;
          white-space: normal;
          height: auto;
          min-width: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          background: #FFFFFF;
  
          .button-text {
            min-width: 0;
            flex: 1;
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            color: #07A197;
            margin: 0px 8px;
            text-align: left;
          }
          .icon-style{
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  .infinite-scroll-component  {
    .promos-container {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;

      & > * {
        min-width: 0;
        margin: 10px;
      }
    }

    .no-promos {
      display: flex;
      flex-flow: column;
      color: black;
      min-height: 200px;
      text-align: center;
      justify-content: center;
      align-items: center;
      margin: 32px 62px;
      .no-promos-text {
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: #686868;
        margin: 16px 0px;
      }
    }

    .spin-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }
    
  }
}
