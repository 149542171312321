.page-layout {
  height:100%;
  background-color: #06B6AA;
  &.home-layout{
    background-color: #F2F2F2;
  } 
  &.no-spacing{
    background-color: white;
  }
  
  .page-content {
    display: flex;
    flex-flow: column;
    min-height: calc(100% - 60%);
  
    &.no-min-height{
      min-height: unset;
    }
  
    // background-color: #E5E5E5;
    background-color: white ;

    .back-container {
      position: relative;
      align-self: start;
      
      .back-arrow {
        position: absolute;
        left: 0;
        top: 0; 
        padding: 10px 0 0 5px;
      }
    }
  
    .title-container {
      background-color: white;
      padding: 0 20px;
      margin: -1px 0px;

      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        color: #FF8555;
        margin-bottom: 0;
      }
    }
  }
}