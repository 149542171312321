.category-card {
  border-radius: 5px;
  width: 100px;
  min-height: 100px;
  padding: 10px;
  border: 2px solid #E5E5E5;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  position: relative;

  &.selected {
    border: 2px solid #06B6AA;
  }

  .category-icon {
    width: 50px;
    height: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .category-name {
    min-width: 0;

    margin-top: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #686868;

    &.selected {
      color:#06B6AA;
    }
  }

  .selected-check {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 16px;
    height: 16px;
    color: #06B6AA;
    z-index: 1;
  }

  .disable-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: #FFFFFF;
    opacity: 0.5;
  }
}
