.qr-code {
  background: white;
  display: flex;
  flex-flow: column;
  padding: 15px 0px;
  border-radius: 4px;
  width: 243px;
  & > * {
    margin-right: auto;
    margin-left: auto;
  }

  .qr-code-promo-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #272727;
    margin: 5px 12.46px;
  }
  .qr-code-promo-valid {
    margin-top: 4px;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: #686868;
  }
}
