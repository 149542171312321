.stores-list {
  background-color: white;
  padding: 12px 16px;
  width:100%;
  &.add-bm {
    margin-bottom: 8px ;
  }

  & > * {
    margin-bottom: 14px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  .store-list-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #363636;
  }
  .store-image {
    margin-right: 6.82px;
  }
  .no-stores-text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #272727;
  }
  .store-row {
    overflow: hidden;

    .store-title {
      display: flex;
      flex-flow: row;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      color: #272727;
      .store-owner{
        font-weight: 400;
      }
    }
    .store-address {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
    
  }
  .store-divider{
    border: 0.5px solid #E0E0E0;
  }
  .stores-button{
    color: #F26B37 !important;
    border-color: #F26B37 !important;
    padding: 8px 12px;
    width: inherit;
    height: 40px;
    min-height: unset !important;
    font-size: 14px;
    line-height: 20px;
  }
}
