.onboarding-step {

  .page-content {
    height: 100%;
    display: flex;
    flex-flow: column;

    .onboarding-container {
      flex: 1;
      overflow: auto;

      .section-container {
        background: #06B6AA;
        padding: 10px 40px;
    
        .section-title {
          margin-top: 16px;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 28px;
          text-align: center;
          color: #FFFFFF;
        }
    
        .section-sub-title {
          margin-top: 8px;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: #FFFFFF;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: center;
      width: 100%;
      background-color: #FFFFFF;
      z-index: 1;
      box-shadow: -5px -2px 4px 4px rgba(145, 145, 145, 0.25);
      padding: 10px 20px;

      .action {
        width: 100%;
      }
    }
  }

  
}