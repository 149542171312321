.qr-section {
  margin: 40px 20px 24px 20px;
  display: flex;
  flex-flow: column;
  align-items: center;

  &.pb-none {
    padding-bottom: 0;
  }

  & > * {
    margin-bottom: 14px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  .qr-section-title {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    line-height: 18px;
    color: #07A197;
  }

  .qr-section-description {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    margin: 0px 20px 10px;
  }
  
}
.button{
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  min-height: 48px;
  border-radius: 4px;
  width: 243px;
}