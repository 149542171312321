.content-template {
  width: 100%;
  margin-top: -1%;
  padding: 24px;
  background: linear-gradient(to bottom, #06B6AA 0px, #06B6AA 80px, rgba(0, 0, 0, 0) 80px, rgba(0, 0, 0, 0) 100%);
  flex: 1;

  .content {
    background: white;
    border-radius: 25px;
    padding: 24px 16px;
    z-index: 1;
    position: relative;
    box-shadow: 0px 5px 11px 4px rgba(0, 0, 0, 0.25);

    & > * {
      margin-bottom: 16px;
    }

    & > :last-child {
      margin-bottom: 0;
    }

    .header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .back {
        width: 12px;
        margin-right: 16px;
      }
      
      .placeholder {
        width: 12px;
        margin-left: 16px;
      }
      
      .title {
        flex: 1;
        color: #525252;
        margin: 0;
        text-align: center;

        &.large {
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
        }

        &.small {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    .button {
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      min-height: 48px;
      border-radius: 4px;
      width: 100%;
    }
  }
}



