.login-page {
  .content {
    display: flex;
    flex-flow: column;
    align-items: center;

    & > * {
      margin-left: auto;
      margin-right: auto;
    }
    &.enter-number {
      background-color: white;
      padding: 20px;
    }
    .image {
      width: 100px;
    }

    .title {
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: #525252;
    }

    .sub-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #525252;
      &.enter-number {
        color: #979797;
        font-weight: bold;
      }
    }

    .number-title {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #979797;
    }

    .ant-input-affix-wrapper {
      width: 200px;
      border-width: 0 0 1px;
      border-right: unset;
      border-radius: unset;
      box-shadow: none;
      text-align: center;
      margin-bottom: 15px;
      .ant-input-prefix {
        padding: 0 5px;
      }

      .ant-input {
        padding: 0 10px;
        border-left: 1px solid #979797;
        text-align: left;
      }
    }

    .number-input-container {
      display: flex;

      .number-input {
        border-width: 0 0 2px;
        outline-color: coral;

        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #525252;
        padding: 5px 12px;

        border-bottom: 2px solid #979797;
        max-width: 160px;

        &.country-code {
          width: 34px;
          border-right: 2px solid #979797;
        }
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
       margin: 0;
    }

    .otp-container {
      .otp-input {
        width: 25px !important;
        border-width: 0;
        border-bottom: 2px solid #000000;
        outline: none;
        margin-right: 5px;
        font-weight: bold;
        font-size: 28px;
        line-height: 42px;
        color: #000000;
        padding: 0;

        &.otp-focus {
          border-bottom: 2px solid coral;
        }

        &.otp-error {
          border-bottom: none;
          outline: auto;
          outline-width: 2px;
          outline-color: red;
        }
      }
    }

    .no-otp-receive {
      font-weight: normal;
      font-size: 12px;
      line-height: 119.5%;
      text-align: center;
      color: #979797;

      .resend-otp,
      .resend-otp:focus,
      .resend-otp:hover,
      .resend-otp:active {
        color: mediumturquoise;
        background: white;
        border: none;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}
