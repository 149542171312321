.page-header {
  padding: 15px;
  width: 100%;
  min-height: 80px;
  background-color: #06B6AA;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  .suki-logo {
    background-color: #06B6AA;
    height: 72px;
    width: 100%;
  }

  .suki-logo-sticky {
    z-index: 1;
    position: fixed;
    top:0;
    left:0;
  }

  .store-logo{
    width: 100%;
    max-width: 360px;
    max-height: 225px;
    margin-left: -22px;
  }
}
