.category-carousel {
  align-items: center;
  padding: 10px 16px 0px 10px;
  margin: 0px 16px 14px 0px;
  position: sticky !important;
  background-color: #F2F2F2;
  z-index: 1;
  top: 0;
  &.drop-shadow {
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  }
}
// .sticky_sentinel {
//   position: absolute;
//   left: 0;
//   right: 0; /* needs dimensions */
//   visibility: hidden;
// }
// .sticky_sentinel--top {
//   /* Adjust the height and top values based on your on your sticky top position.
//   e.g. make the height bigger and adjust the top so observeHeaders()'s
//   IntersectionObserver fires as soon as the bottom of the sentinel crosses the
//   top of the intersection container. */
//   height: 104px;
//   top: -24px;
// }
.category {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 5px;
  .status-indicator {
    background-color: #07A197;
    border: 2px solid #07A197;
    width: 42px;
  }
}
.category-image {
  background: white;
  width: 42px;
  height: 42px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.selected{
    background: #06B6AA;
  }
}
.category-svg {
  width: 24px;
  height: 24px;
  fill: #06B6AA;
  &.selected{
    fill: white;
  }
}
.category-title {
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  margin: 8px 0 ;
  color: #686868;
  max-width: 59px;  
  width: max-content;
  max-height: fit-content;
  &.selected {
    color: #06B6AA;
    font-weight: bold;
  }
}