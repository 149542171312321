.form-item-container {
    max-width: 200px;
    margin-right: auto;
    margin-left: auto;

.label {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #979797;

    .required-asterisk {
    color: red;
    }
}

.ant-form-item-explain-error {
    color: #C51B1B;
    font-size: 11px;
    text-align: center;
    margin-bottom: 20px;
}

.input,
.dropdown-anchor {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #525252;
}

.ant-select {
    &.location-select {
        text-align: center;
    }
    .ant-select-selector {
    width: 100%;
    border-width: 0 0 1px;
    border-right: unset;
    border-radius: unset;
    }
    .ant-select-arrow {
    color:#06B6AA;
    }
    // .ant-select-selection-search-input{
    //     text-align: center;
    // }
}
}