.provider-card {
    background: #FFFFFF;
    border: 1px solid #E7ECEE;
    border-radius: 5px;
    min-height: 68px;
    padding: 12px;
    display: flex;
    align-items: center;
    width: 100%;
  
    &:active,
    &:focus,
    &:hover,
    &.selected {
      border: 1px solid #06B6AA;
    }
  
    .card-icon {
      width: 40px;
      height: 40px;
      margin-right: 8px;
      align-items: flex-start;
    }
  
    .details-container {
      flex: 1;
      min-width: 0;
      overflow: hidden;
  
      .details-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #363636;
      }
  
      .details-sub-title {
        font-size: 12px;
        line-height: 16px;
        color: #363636;
      }
    }
  
    .card-check-icon {
      margin-left: 8px;
      align-items: flex-end;
    }
}
  