.voucher-card {
  background: #FFFFFF;
  border: 1px solid #E7ECEE;
  border-radius: 5px;
  min-height: 68px;
  padding: 12px;
  margin: 12px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:active,
  &:focus,
  &:hover {
    border: 1px solid #06B6AA;
  }

  .card-icon {
    width: 50px;
    height: 50px;
    margin-right: 8px;
  }

  .details-container {
    flex: 1;
    min-width: 0;
    overflow: hidden;

    .details-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #1D262C;
    }

    .details-sub-title {
      font-size: 12px;
      line-height: 16px;
      color: #686868;
      margin: 6px 0px;
    }

    .view-promo {
      display: flex;
      flex-flow: row;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: #06B6AA;
  
      .arrow-style{
        margin-left: 4.5px;
      }
    }
    .voucher-status {
      display: flex;
      width: fit-content;
      margin: 6px 0px;
      padding: 2px 8px;
      background: rgba(252, 171, 139, 0.1);
      border-radius: 100px;
      border: 0.25px solid #E9622E;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: #E9622E;
      text-align: center;

      &.expired {
        background: rgba(218, 35, 35, 0.1);
        border: 0.25px solid #DA2323;
        color: #DA2323;
      }
  
      &.redeemed {
        background: rgba(0, 128, 0, 0.1);
        border: 0.25px solid #008000;
        color: #008000;
      }
      .status-icon{
        width: 10px;
        height: 10px;
        margin: 5px 4px 5px 0px;
        align-self: center;
      } 
      .status-text{
        align-self: center;
        margin-top:1px;
      }
    }
  }
}
