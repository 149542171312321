.promo-discount{
  display: flex;
  background-color: #06B6AA;
  padding: 1px 10px;
  border-radius: 4px;
  align-self: center;
  height: 23px;
  width: fit-content;

  .discount-text{
    background-color: #E9622E;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    text-align: center;
    align-self: center;
    height: 23px;
    width: max-content;
  }
  .left-ellipse {
    position: relative;
    background-color: white;
    border-radius: 10px;
    min-width: 6px;
    max-height: 6px;
    left: -12px;
  }
  .right-ellipse {
    position: relative;
    background-color: white;
    border-radius: 10px;
    min-width: 6px;
    max-height: 6px;
    right: -12px;
  }
}
