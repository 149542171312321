.registration-page {
  .form {
    padding: 20px;
  
    .form-item-container {
      max-width: 300px;
      margin-right: auto;
      margin-left: auto;
    }
  
    .terms {
      font-weight: normal;
      font-size: 12px;
      line-height: 119.5%;
      color: #979797;
      margin: 0px 8px;
  
      .terms-button {
        color: mediumturquoise;
        background: transparent;
        font-weight: normal;
        border: none;
        text-decoration: underline;
        padding: 0;
      }
    }
  
    .label {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #979797;
      margin-bottom: 8px;
  
      .required-asterisk {
        color: #979797;
      }
    }
  
    .ant-form-item {
      margin-bottom: 24px;
    }
  
    .ant-form-item-explain-error {
      color: #C51B1B;
      font-size: 11px;
      text-align: center;
      margin-bottom: 20px;
    }
    
    .input,
    .dropdown-anchor {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #525252;
    }
    
    .ant-checkbox-inner{
      transform: scale(1); 
      border-width: 1pt;
      border-color: #06B6AA ;
    }
  
    .ant-input-affix-wrapper {
      .ant-input-prefix {
        padding: 0 5px;
      }
  
      .ant-input {
        padding: 0 10px;
        border-left: 1px solid #979797;
        text-align: left;
      }
    }
  
    .ant-input,
    .ant-input-affix-wrapper {
      width: 100%;
      border-width: 0 0 1px;
      border-right: unset;
      border-radius: unset;
      box-shadow: none;
      text-align: center;
    }
  
    .ant-select {
      &.gender-select {
        text-align: center;
      }
  
      .ant-select-selector {
        width: 100%;
        border-width: 0 0 1px;
        border-right: unset;
        border-radius: unset;
      }
  
      .ant-select-arrow {
        color:#06B6AA;
      }
    }
  
    .ant-picker {
      .ant-picker-input {
        input {
          text-align: center;
        }
        .ant-picker-suffix {
          color:#06B6AA;
        }
      }
    }
  }
}

.terms-and-conditions {
  .terms-paragraph {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
  .terms-link {
    margin-left: 4px;
    text-decoration: underline;
  }
  .terms-heading {
    text-align: center;
  }
}

.terms-modal {
  .ant-modal {
    .ant-modal-content {
      .ant-modal-header {
        border: none;
        .title {
          font-weight: bold;
          font-size: 18px;
          line-height: 28px;
          color:#F06B40;
        }
      }

      .ant-modal-body {
        padding: 0 24px;
        overflow-y: auto;
        .body {
          background-color: transparent;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
        }
      }

      .ant-modal-footer {
        .button {
          background-color: #F26B37;
          color: #FFFFFF;
          font-weight: bold;
          font-size: 16px;
          line-height: 150%;
          min-height: 48px;
          border-radius: 4px;
          width: 100%;
      
          &:focus,
          &:active,
          &:hover {
            background-color: #F26B37;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}
  