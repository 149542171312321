.detail-container {
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(145, 145, 145, 0.25);
  padding: 8px;
  width: 156px;
  cursor: pointer;

  display: flex;
  flex-flow: column;
  align-items: flex-start;

  & > * {
    min-width: 0;
    margin-top: 4px;
  }

  .promo-image {
    max-width: 140px;
    max-height: 100px;
    margin-top: 0;
    align-self: center;
  }

  .details-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #272727;
    // text-align: center;
  }

  .details-sub-title {
    margin-top: auto;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #686868;
  }

  .view-promo {
    display: flex;
    flex-flow: row;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: #06B6AA;
    align-items: center;

    .arrow-style{
      margin-left: 4.5px;
      width: 4px;
      height: 16px;
    }
  }
}
