.telco-providers{
  .telco-view{
    display: flex;
    flex-flow: column;
    align-items: center;  
    background-color: #06B6AA;
  }
  .button{
    &.continue-button{
      width: 90%;
    }
  }
  .unique{
    background: unset;
  }
  .content {
    display: flex;
    flex-flow: column;
    align-items: center;  
    & > * {
        margin-left: auto;
        margin-right: auto;
    }
  }
  .success-trophy {
    width: 100px;
    align-self: center;
    margin: 10px 0px;
  }  
  .congrats-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    margin: -5px 19%;
  }  
  .sub-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #525252;
  }
  
}