.register-preferences {
  &.onboarding-step {
    .page-content {
      .content {
        padding: 10px;

        .category-list {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          align-items: stretch;
          justify-content: center;

          & > * {
            min-width: 0;
            margin: 10px;
          }
        }
      }

      .spin-container {
        display: flex;
        justify-content: center;
      }
    }
  }
}
  