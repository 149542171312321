body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    position: relative;
    height: 100%;
    background-color: #06B6AA;

    .app {
      height: 100%;

      .button {
        background-color: #F26B37;
        color: #FFFFFF;
  
        &:focus,
        &:active,
        &:hover {
          background-color: #F26B37;
          color: #FFFFFF;
        }
  
        &:disabled {
          background-color: #90b5be;
          color: #C8D0D2;
        }
  
        &.outlined {
          background-color: transparent;
          color: #E9622E;
          border: 2px solid #E9622E;
        }
      }
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-checkbox-inner{
  transform: scale(1.5);  
}

.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color:#e0e0e0;
}

.ant-checkbox:hover .ant-checkbox-inner{
  border-color: #06B6AA;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color  :white;
  background-color: #06B6AA;
}

.ant-spin-dot-item{
  background-color: orangered;
}

.ant-select-dropdown {
  .ant-select-item {
    &.ant-select-item-option {
      .ant-select-item-option-content {
        text-align: center;
      }
    }
  } 
}
.ant-tabs-card .ant-tabs-content {
  position: absolute;
  width: 100%;
  margin-top: -16px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  background-color: #07a197;
  border-radius: 0;
  border-color: transparent;
  width: 100%;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar {
  visibility:visible;
  background-color: #32EADD;
  height: 4px;
}
// .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
//   border-bottom-width: 4px;
//   border-bottom-color: #32EADD;
// }
.ant-tabs-tab .ant-tabs-tab-btn {
  color: white;
  opacity: 0.5;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
  opacity: 1;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin:0px;
}
.ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: #FAFAFA;
}
.ant-tabs > .ant-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  align-self: center;
}
.ant-tabs{
  background-color: #07a197 ; // just added color but cards are not stretched.
}