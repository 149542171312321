.homepage_tutorial_modal{
  padding: 10px !important;
  margin: 0 auto !important;

  .ant-modal-content{
    width: 320px;
    height: 547px;
    border-radius: 25px;
    margin: 0 auto;
    padding: 20px;

    .carousel.carousel-slider {
      border-radius: 25px;
    }
  }

  .carousel .control-dots .dot { 
    width: 15px;
    height: 15px;
    border: 2px solid #F26B37;
    box-shadow: none;
  }
  .carousel .control-dots .dot.selected {
    background-color: #F26B37;
  }
  .carousel-item{
    height: 100%;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .carousel-title{
    color: #F26B37;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
  }

  .carousel-image{
    border-radius: 8px;
    width: 200px !important;
    height: 200px;
    margin-bottom: 10px;
  }
  
  #carousel-image-1 {
    width: 256px !important;
    height: 157px !important;
    border-radius: 0px;
    border: 2px solid #FFF
  }
  
  .carousel-text{
    color: #262338;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 18px 34px 40px;
  }

  .carousel-button {
    width: 100%;
    color: #FFF;
    background-color: #F26B37;
  }
}
