.coming-soon-page {
    background-color: #06B6AA;
    .image {
        flex:-1;
        width: 100%;
    }
    .image-stars{
        width: 30px;
        height: 22px;
        margin-top: -10px;
    }
    .title-div{
        position: relative;
        margin: 5px 0px;
        padding: 2% 0%;
        background: #FFFFFF;
        text-align: center;
    }
    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        color: #07A197;
    }
    .sub-title {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #272727;
        padding: 0% 15%;
        padding-top: 2%;
    }
    .message {
        flex: 1;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        color: #FFFFFF;
        padding: 19px 18%;
    }
}