.redeem-div{
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 14px 16px;
  width: 100%;
  margin-bottom: 60px;

  & > :first-child {
    margin-bottom: 16px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

}
.question-mark{
  // width: 20px;
  // height: 20px;
  margin-right: 6px;
}
.redeem-title{
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #363636;
}
.steps{
  display: flex;
  flex-direction: row;
}
.step-image{
  width: 62px;
  height: 62px;
  border-radius: 4px;
  margin: 0 12px 12px 0;
}
.step-title{
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #525252;
  margin: 0 0 4px 0;
}
.step-text{
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #525252;
}
