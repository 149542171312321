.saved-promos {
  height: 100%;

  .page-content {
    height: 100%;
    background-color: #fafafa;
  }

  .tab-pane {
    width: 50vw;
  }

  .saved-promo-header {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #06B6AA;
    padding: 0 0 12px 16px;
    
    .saved-promo-text {
      align-self: end;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #FFFFFF;
      text-align: left;
    }

    .saved-promo-image {
      height: 154px;
      width: 166px;
    }
  }

  .spin-container {
    display: flex;
    justify-content: center;
  }

  .no-promos-div {
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;
    top: 46px;
    margin: 0px 36px;

    & > * {
      margin-bottom: 24px;
    }
    & > :last-child {
      margin-bottom: 0;
    }

    .no-promos-image{
      width: 200px;
      height: 100px;
    }
    .no-promos-text{
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #272727;
    }
    .all-promos-button {
      width: fit-content;
    }
  }
}