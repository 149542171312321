.self-registration-page {
  .content {
    display: flex;
    flex-flow: column;
    align-items: center;

    & > * {
      margin-left: auto;
      margin-right: auto;
    }

    .title {
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: #07A197;
    }

    .sub-title {
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #525252;
      margin-bottom: 20px;
    }
  }
}
  