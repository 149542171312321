.promo-details{
  background-color: #E5E5E5;
  width: 100%;
  overflow: auto;

  .page-content {
    background-color: #E5E5E5;
    background: linear-gradient(to bottom, #06B6AA 0px, #06BAAA 126px, rgba(0, 0, 0, 0) 126px, rgba(0, 0, 0, 0) 100%);
    display: flex;
    align-items: center;
  }

  .promo-image {
    margin-top: 16px;
    width: 257px;
    height: 188px;
    margin-bottom: 24px;
  }
  .promo-details-view{
    background-color: #FFFFFF;
    width: 100%;
    margin-bottom: 8px;
    padding: 12px 16px;
  }
  .promo-discount{
    background-color: #E9622E;
    padding: 1px 10px;
    border-radius: 4px;
    // text
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }
  .promo-title {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #525252;
  }
  .promo-sub-title {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #525252;
  }
  .heading {
    display: flex;
    flex-direction: row;
    padding-bottom: 4px;
  }
  .sub-title-heading{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #363636;
    padding-left: 5px;
  }
  .title-heading{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #272727;
    padding-left: 5px;
  }
  .save-promo{
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #FFFFFF;
    z-index: 1;
    box-shadow: -5px -2px 4px 4px rgba(145, 145, 145, 0.25);
    padding: 12px 16px;

    .save-promo-button{
      width: 100%;
      
      &:disabled {
        background-color: #F7F7FC !important;
        color: #6E7191 !important;
      }
    }
  }
}
.ant-modal-body{
  padding: 0;

  .register-login-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    
    .register-login {
      width: 100px;
      height: 100px;
      border-radius: 100px;
    }

    .register-login-text {
      width: 221px;
      height: 48px;
      text-align: center;
      margin: 10px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #272727;
    }

    .continue-button {
      background-color: #F26B37;
      color: #FFFFFF;    }
  }
}

